import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getUserInfo, getAccountStatus, checkAuthUser, getIp } from "@/api";

const initialState = {
	"account": "",
	"email": "",
	"phone": "",
	"first_name": "",
	"last_name": "",
	"BSC": "",
	"TRON": "",
	"invite_code": "",
	"has_trial": 0,
	"has_referral_bonus": 0,
	"has_invest": 0,
	total_profit: 0,
	invest_amount: 0,
	static_profit: 0,
	dynamic_profit: 0,
	country: "", // kyc country
	uuid: "",
	sales: [],
	status: 1, // 1: real 2: simulate
	is_illegal: 0,
	lock_income: 0,
	is_studio: 0,
	apply_studio: 0,
	user_data: 0,
	country_en: "", // ip country
	init: true
};

export const getUserProfile = createAsyncThunk("user/getUserProfile", async () => {
	const { data } = await getUserInfo();
	return data;
});

export const getAccountStatusThunk = createAsyncThunk("user/getAccountStatus", async () => {
	const { data } = await getAccountStatus();
	return data;
});

export const checkAuthUserThunk = createAsyncThunk("user/checkAuthUser", async () => {
	const { data } = await checkAuthUser();
	return data;
});

export const getIpThunk = createAsyncThunk("user/getIp", async () => {
	const { data } = await getIp();
	return data;
});

export const userSlice = createSlice({
	name: "user",
	initialState,
	reducers: {
		setUserProfile: (state, { payload }) => {
			const {
				account, email, first_name, last_name, BSC, TRON, country, isKyc, sales, total_profit,
				has_invest, has_referral_bonus, has_trial, phone, uuid, areaCode, level, dynamic_level, invest_amount,
				static_profit, dynamic_profit, is_illegal, lock_income, is_studio, apply_studio
			} = payload;
			state.account = account;
			state.uuid = uuid;
			state.email = email;
			state.phone = phone;
			state.name = `${first_name} ${last_name}`;
			state.firstName = first_name;
			state.lastName = last_name;
			state.address = {
				BSC,
				TRON
			};
			state.isKyc = isKyc;
			state.level = level;
			state.dynamic_level = dynamic_level;
			state.country = country;
			state.has_invest = has_invest;
			state.has_referral_bonus = has_referral_bonus;
			state.has_trial = has_trial;
			state.areaCode = areaCode;
			state.sales = sales;
			state.total_profit = total_profit;
			state.invest_amount = invest_amount;
			// static_profit invest
			state.static_profit = static_profit;
			// dynamic_profit community
			state.dynamic_profit = dynamic_profit;
			state.is_illegal = is_illegal;
			state.lock_income = lock_income;
			state.is_studio = is_studio;
			state.apply_studio = apply_studio;
			state.init = false;
		},
	},
	extraReducers(builder) {
		builder
			.addCase(getUserProfile.fulfilled, (state, action) => {
				if (action.payload) {
					userSlice.caseReducers.setUserProfile(state, action);
				}
			})
			.addCase(getAccountStatusThunk.fulfilled, (state, action) => {
				if (state.status !== -1) {
					state.status = action.payload;
				}
			})
			.addCase(checkAuthUserThunk.fulfilled, (state, { payload }) => {
				state.status = payload.auth === 0 ? -1 : 0;
				state.user_data = payload.user_data;
			})
			.addCase(getIpThunk.fulfilled, (state, action) => {
				state.country_en = action.payload.country_en;
			});
	},
});

export const {setUserProfile} = userSlice.actions;

export default userSlice.reducer;
