import React from "react";
import "./index.css";

function RichText({ content, className }) {
	return (
		<div
			className={`richtext ql-editor ${className}`}
			dangerouslySetInnerHTML={{ __html: content }}
		/>
	);
}

export default RichText;