import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	count: 0
};

export const loadingSlice = createSlice({
	name: "loading",
	initialState,
	reducers: {
		addLoading: (state) => {
			state.count++;
		},
		reduceLoading: (state) => {
			state.count < 0 ? state.count = 0 : state.count--;
		},
		resetLoading: (state) => {
			state.count = 0;
		}
	},
});

export const { addLoading, reduceLoading, resetLoading } = loadingSlice.actions;

export default loadingSlice.reducer;
