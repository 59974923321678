import React, { useEffect } from "react";

function ErrorPage() {

	useEffect(() => {
		if (import.meta.env.MODE === "production") {
			const errorCount = parseInt(localStorage.getItem("errorCount") || 0);
			if (errorCount >= 3) {
				location.href = "/";
			} else {
				localStorage.setItem("errorCount", errorCount + 1);
				window.location.reload();
			}
		}
	}, []);

	return (
		<div className="flex items-center justify-center flex-col text-[13px] text-primary-600 h-[100vh]">
		</div>
	);
}

export default ErrorPage;