import React from "react";
import { useTranslation } from "react-i18next";
import CenterModal from "@/components/CenterModal";
import CustomButton from "@/components/CustomButton";
import RichText from "@/components/RichText";

function NewsModal({ news, onClose }) {
	const { t } = useTranslation();

	const onCloseHandler = () => {
		const newsModal = JSON.parse(localStorage.getItem("newsModal") || "[]");
		if (!newsModal.includes(news.id)) {
			newsModal.push(news.id);
			localStorage.setItem("newsModal", JSON.stringify(newsModal));
		}
		onClose();
	};

	return (
		<CenterModal
			onClose={onCloseHandler}
			className="px-[20px] !top-[20%] md:!mx-auto !mx-[30px]"
			disableClose="true"
		>
			<p className="text-word-100 font-[600]">{news.title}</p>

			<RichText className="text-[13px] text-word-100 my-[15px] max-h-[250px] overflow-scroll whitespace-pre-line !px-0"
				content={news.content}
			/>

			<CustomButton
				onClick={onCloseHandler}
			>
				{t("confirm")}
			</CustomButton>
		</CenterModal>
	);
}

export default NewsModal;