import {configureStore} from "@reduxjs/toolkit";
import userReducer from "./userSlice";
import balanceSlice from "./balanceSlice";
import loadingSlice from "./loadingSlice";
import configSlice from "./configSlice";
import timestampSlice from "./timestampSlice";
import livechatSlice from "./livechatSlice";
import modalSlice from "./modalSlice";

const store = configureStore({
	reducer: {
		user: userReducer,
		balance: balanceSlice,
		loading: loadingSlice,
		config: configSlice,
		timestamp: timestampSlice,
		livechat: livechatSlice,
		modal: modalSlice
	},
});
export default store;
