import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getUserBalance } from "@/api";

const initialState = {
	"principal": "0.00",
	"investPrincipal": "0.00",
	"trialInvestPrincipal": "0.00",
	"trialProfit": "0.00",
	"instantPrincipal": "0.00",
	"communityFund": "0.00",
	"waitPayIncome": "0.00"
};

export const getUserBalanceAsync = createAsyncThunk("balance/getUserBalance", async () => {
	const { data } = await getUserBalance();
	return data;
});

export const balanceSlice = createSlice({
	name: "balance",
	initialState,
	reducers: {
		setBalance: (state, { payload }) => {
			state.principal = payload.principal;
			state.investPrincipal = payload.investPrincipal;
			state.trialInvestPrincipal = payload.trialInvestPrincipal;
			state.trialProfit = payload.trialProfit;
			state.instantPrincipal = payload.instantPrincipal;
			state.communityFund = payload.communityFund;
			state.waitPayIncome = payload.waitPayIncome;
		},
	},
	extraReducers(builder) {
		builder
			.addCase(getUserBalanceAsync.fulfilled, (state, action) => {
				if (action.payload) {
					balanceSlice.caseReducers.setBalance(state, action);
				}
			});
	},
});

export const { setBalance } = balanceSlice.actions;

export default balanceSlice.reducer;
