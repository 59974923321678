import React, { useEffect, useRef } from "react";
import { ReactComponent as Cross } from "@/assets/svg/cross.svg";
import ReactDOM from "react-dom";

function CenterModal({ className, children, onClose=() => {}, disableClose, crossClassName, disableBgClick }) {
	const modalRef = useRef("");

	useEffect(() => {
		document.body.style.overflow = "hidden";
		return () => {
			document.body.style.overflow = "auto";
		};
	}, []);

	const voidFunc = () => {};

	return (
		ReactDOM.createPortal(
			<>
				<div
					className="bg-secondary-300 opacity-[80%] fixed z-[100] bottom-0 left-0 right-0 top-0"
					onClick={disableBgClick ? voidFunc : onClose}
				/>
				<div
					ref={modalRef}
					className={`fixed z-[100] top-[30%] left-0 right-0 rounded-[11px] mx-[50px] flex flex-col items-center
						justify-center bg-primary-200 animate-slide-in p-[30px] overscroll-contain ${className}
						md:pcAdapt
					`}
				>
					{!disableClose &&
						<Cross
							className={`absolute left-[10px] top-[10px] ${crossClassName}`}
							onClick={onClose}
						/>
					}
					{children}
				</div>
			</>
			,
			document.body 
		)
	);
}

export default CenterModal;