import React, { useState, useEffect } from "react";
import { createPortal } from "react-dom";

const eventBus = new EventTarget();

const showMessage = (msg, timeout=2000) => {
	eventBus.dispatchEvent(new CustomEvent("globalMessage", { detail: { text: msg, timeout } }));
};

const clearMessage = () => {
	eventBus.dispatchEvent(new CustomEvent("globalMessage", { detail: "" }));
};

const GlobalMessage = () => {
	const [message, setMessage] = useState("");

	useEffect(() => {
		eventBus.addEventListener("globalMessage", handleMessage);
		return () => {
			eventBus.removeEventListener("globalMessage", handleMessage);
		};
	}, []);

	const handleMessage = ({ detail }) => {
		setMessage(detail.text);
		setTimeout(() => {
			setMessage("");
		}, detail.timeout);
	};

	const Msg = (
		<div className="fixed top-[50%] left-[50%] translate-x-[-50%] z-[2000] translate-y-[-50%]">
			<p className="text-[14px] p-[10px] mb-[10px] border border-[#ffffff38] rounded-[7px] text-primary-200
				w-[80vw] text-center max-w-[300px] whitespace-pre-line bg-[#162b4694]"
			>
				{message}
			</p>
		</div>
	);

	return message && createPortal(Msg, document.body);
};

export { showMessage, clearMessage };
export default GlobalMessage;
