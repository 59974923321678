import React, { useEffect, Suspense, lazy, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { resetShow, setShow } from "@/store/modalSlice";
const BannedModal = lazy(() => import("@/components/BannedModal"));

function GernalModal() {
	const dispatch = useDispatch();
	const interval1 = useRef(null);
	const modal = useSelector(state => state.modal);
	const user = useSelector(state => state.user);

	useEffect(() => {
		if (user.is_illegal) {
			dispatch(setShow("bannedModal"));
			interval1.current = setInterval(() => {
				dispatch(setShow("bannedModal"));
			}, 5000);
		}
		return () => {
			clearInterval(interval1.current);
		};
	}, [user.is_illegal]);

	return (
		<Suspense >
			{modal.show === "bannedModal" &&
				<BannedModal onClose={() => dispatch(resetShow())}/>
			}
		</Suspense>
	);
}

export default GernalModal;