import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getSystemTime } from "@/api";

const initialState = {
	value: new Date().getTime() / 1000,
};

export const getSystemTimeSync = createAsyncThunk("timestamp/getTime", async () => {
	const { data } = await getSystemTime();
	return data;
});

export const timestampSlice = createSlice({
	name: "timestamp",
	initialState,
	reducers: {
		addTimestamp: (state) => {
			state.value++;
		},
		setTimestamp: (state, { payload }) => {
			state.value = parseInt(payload) + 1;
		},
	},
	extraReducers(builder) {
		builder
			.addCase(getSystemTimeSync.fulfilled, (state, action) => {
				if (action.payload) {
					timestampSlice.caseReducers.setTimestamp(state, action);
				}
			});
	},
});

export const { addTimestamp, updateTimestamp } = timestampSlice.actions;

export default timestampSlice.reducer;
