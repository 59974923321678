import request from "./request";
import simulateRequest from "./simulateRequest";

let store;

export const injectStore = _store => {
	store = _store;
};

export const emailRegister = data => {
	return request.post("/register/email", data);
};


export const uploadKycVideo = data => {
	return request.post("/upload/video", data, {headers: { "Content-Type": "multipart/form-data" }});
};

export const uploadKycPhoto = data => {
	return request.post("/upload/photo", data);
};

export const updateUserInfo = data => {
	return request.post("/update/user_info", data);
};

export const getUserInfo = () => {
	if ([0, -1].includes(store.getState().user.status)) return;
	if (store.getState().user.status === 1) {
		return request.get("/user_info");
	} else {
		return simulateRequest.get("/simulate/user_info");
	}
};

export const getUserBalance = () => {
	if ([0, -1].includes(store.getState().user.status)) return;
	if (store.getState().user.status === 1) {
		return request.get("/user/balance");
	} else {
		return simulateRequest.get("/simulate/user/balance");
	}
};

export const getNewsList = () => {
	return request.get("/news/list");
};

export const getNewsContent = data => {
	return request.post("/news/content", data);
};
 
export const withdraw = data => {
	return request.post("/withdraw/cash", data);
};

export const accountLogin = data => {
	return request.post("/login", data);
};

export const accountRegister = data => {
	return request.post("/register", data);
};

export const marginNew = data => {
	return request.post("/margin/new", data);
};

export const getMarginTask = () => {
	return request.get("/create/margin_task");
};

export const marginWithdraw = data => {
	return request.post("/margin/withdraw", data);
};

export const getCurrentTask = () => {
	return request.get("/current_task");
};

export const getMatchTask = () => {
	return request.get("/match_task");
};

export const updateTask = data => {
	return request.post("/update/user_task", data);
};

export const getNoviceTask = () => {
	return request.get("/create/novice_task");
};

export const getMargin = () => {
	return request.get("/margin/get");
};

export const submitTask = data => {
	return request.post("/submit_task", data);
};

export const getTaskList = () => {
	return request.get("/task_list");
};

export const marginCompound = data => {
	return request.post("/margin/compound", data);
};

export const marginHarvest = data => {
	return request.post("/margin/harvest", data);
};

export const getArbiShowList = () => {
	return request.get("/arbi_show_list");
};

export const getArbiInstantList = () => {
	return request.get("/arbi_instant_list");
};

export const getLeagueInfo = data => {
	return request.post("/league_info", data);
};

export const getTeamInfo = data => {
	return request.post("/team_info", data);
};

export const getArbiDetail = data => {
	return request.post("/arbi_detail", data);
};

export const createInstant = data => {
	if ([0, -1].includes(store.getState().user.status)) return;
	if (store.getState().user.status === 1) {
		return request.post("/create/instant", data);
	} else {
		return simulateRequest.post("/simulate/create/instant", data);
	}
};

export const getInstantList = () => {
	if ([0, -1].includes(store.getState().user.status)) return;
	if (store.getState().user.status === 1) {
		return request.get("/instant_list");
	} else {
		return simulateRequest.get("/simulate/instant_list");
	}
};

export const sendCode = data => {
	return request.post("/sendCode", data);
};

export const resetSendCode = data => {
	return request.post("/user/resetSendCode", data);
};

export const createNewInvest = data => {
	if ([0, -1].includes(store.getState().user.status)) return;
	if (store.getState().user.status === 1) {
		return request.post("/invest/new", data);
	} else {
		return simulateRequest.post("/simulate/invest/new", data);
	}
};

export const getInvestList = () => {
	if ([0, -1].includes(store.getState().user.status)) return;
	if (store.getState().user.status === 1) {
		return request.get("/invest/get");
	} else {
		return simulateRequest.get("/simulate/invest/get");
	}
};

export const getInvestById = (data) => {
	if ([0, -1].includes(store.getState().user.status)) return;
	if (store.getState().user.status === 1) {
		return request.post("/invest/get", data);
	} else {
		return simulateRequest.post("/simulate/invest/get", data);
	}
};

export const updateInvestCompound = data => {
	if ([0, -1].includes(store.getState().user.status)) return;
	if (store.getState().user.status === 1) {
		return request.post("/invest/updateInvestCompound", data);
	} else {
		return simulateRequest.post("/simulate/invest/updateInvestCompound", data);
	}
};

export const updateInvestRenew = data => {
	if ([0, -1].includes(store.getState().user.status)) return;
	if (store.getState().user.status === 1) {
		return request.post("/invest/updateInvestRenew", data);
	} else {
		return simulateRequest.post("/simulate/invest/updateInvestRenew", data);
	}
};

export const investRedeem = data => {
	if ([0, -1].includes(store.getState().user.status)) return;
	if (store.getState().user.status === 1) {
		return request.post("/invest/redeem", data);
	} else {
		return simulateRequest.post("/simulate/invest/redeem", data);
	}
};

export const redeemCancel = data => {
	if ([0, -1].includes(store.getState().user.status)) return;
	if (store.getState().user.status === 1) {
		return request.post("/invest/redeemCancel", data);
	} else {
		return simulateRequest.post("/simulate/invest/redeemCancel", data);
	}
};

export const investProfitWithdraw = data => {
	if ([0, -1].includes(store.getState().user.status)) return;
	if (store.getState().user.status === 1) {
		return request.post("/invest/WithdrawProfit", data);
	} else {
		return simulateRequest.post("/simulate/invest/WithdrawProfit", data);
	}
};

export const getInvestHistory = (data) => {
	if ([0, -1].includes(store.getState().user.status)) return;
	if (store.getState().user.status === 1) {
		return request.post("/invest/history", data);
	} else {
		return simulateRequest.post("/simulate/invest/history", data);
	}
};

export const forceClaim = (data) => {
	if ([0, -1].includes(store.getState().user.status)) return;
	if (store.getState().user.status === 1) {
		return request.post("/invest/not_expired", data);
	} else {
		return simulateRequest.post("/simulate/invest/not_expired", data);
	}
};

export const getVipInfo = () => {
	if ([0, -1].includes(store.getState().user.status)) return;
	if (store.getState().user.status === 1) {
		return request.get("/vip_info");
	} else {
		return simulateRequest.get("/simulate/vip_info");
	}
};

export const getAnnouncement = () => {
	return request.get("/announcement/getAll");
};

export const investTrialProfitWithdraw = data => {
	return request.post("/invest/WithdrawTrailProfit", data);
};

export const getCashAccountLog = data => {
	if ([0, -1].includes(store.getState().user.status)) return;
	if (store.getState().user.status === 1) {
		return request.post("/asset/cashAccount", data);
	} else {
		return simulateRequest.post("/simulate/asset/cashAccount", data);
	}
};

export const getProfitDetailLog = data => {
	if ([0, -1].includes(store.getState().user.status)) return;
	if (store.getState().user.status === 1) {
		return request.post("/asset/profitDetail", data);
	} else {
		return simulateRequest.post("/simulate/asset/profitDetail", data);
	}
};

export const internalTransfer = data => {
	return request.post("/asset/transfer", data);
};

export const getMyFriend = () => {
	return request.get("/user/myFriend");
};

export const getUserProfitStatistics = data => {
	if ([0, -1].includes(store.getState().user.status)) return;
	if (store.getState().user.status === 1) {
		return request.post("/asset/UserProfit", data);
	} else {
		return simulateRequest.post("/simulate/asset/UserProfit", data);
	}
};

export const bindingVerify = data => {
	return request.post("/user/bindUpdate", data);
};

export const resetBindVerify = data => {
	return request.post("/user/resetBindVerify", data);
};

export const resetBindUpdate = data => {
	return request.post("/user/resetBindUpdate", data);
};

export const investRenew = data => {
	if ([0, -1].includes(store.getState().user.status)) return;
	if (store.getState().user.status === 1) {
		return request.post("/invest/renew", data);

	} else {
		return simulateRequest.post("/simulate/invest/renew", data);
	}
};

export const userChangePassword = data => {
	return request.post("/user/changePassword", data);
};

export const userChangePaymentPassword = data => {
	return request.post("/user/changePaymentPassword", data);
};

export const resetPassword = data => {
	return request.post("/user/resetPassword", data);
};

export const forgetPasswordVerify = data => {
	return request.post("/user/forgetPasswordVerify", data);
};

export const getCurrencyList = () => {
	return request.get("/currency_list");
};

export const getCurrentExchangeBuy = () => {
	return request.get("/current/exchange_buy");
};

export const buyUsdt = data => {
	return request.post("/create/exchange_buy", data);
};

export const getBuyDetail = data => {
	return request.post("/get/exchange_buy", data);
};

export const getSellDetail = data => {
	return request.post("/get/exchange_sell", data);
};

export const uploadBuyPhoto = data => {
	return request.post("/upload/exchange_buy", data);
};

export const cancelBuyOrder = data => {
	return request.post("/cancel/exchange_buy", data);
};

export const sellUsdt = data => {
	return request.post("/create/exchange_sell", data);
};

export const getBuyList = () => {
	return request.get("/list/exchange_buy");
};

export const getSellList = () => {
	return request.get("/list/exchange_sell");
};

export const updateBuyOrderToSuccess = data => {
	return request.post("/update/exchange_buy", data);
};

export const getDailySportList = (sport, date) => {
	return request.post(`/events/daily/${sport}`, date);
};

export const getLiveSportList = (sport) => {
	return request.get(`/events/live/${sport}`);
};

export const redeemVipProfit = () => {
	if ([0, -1].includes(store.getState().user.status)) return;
	if (store.getState().user.status === 1) {
		return request.post("/redeem/vipProfit");
	} else {
		return simulateRequest.post("/simulate/redeem/vipProfit");
	}
};

export const getDictionary = () => {
	return request.get("/dictionary/getAll");
};

export const getSystemTime = () => {
	return request.get("/system_time");
};

export const getInstantDetail = data => {
	if ([0, -1].includes(store.getState().user.status)) return;
	if (store.getState().user.status === 1) {
		return request.post("/instant_detail", data);
	} else {
		return simulateRequest.post("/simulate/instant_detail", data);
	}
};

export const getLiveSummariesTotal = () => {
	return request.get("/events/liveSummariesTotal");
};

export const logoutApi = () => {
	return request.get("/loginout");
};

export const getRandAccount = data => {
	return request.post("/registerAccount", data);
};

export const getLoginImageCode = data => {
	return request.post("/loginImageCode", data);
};

export const getAnnouncements = () => {
	return request.get("/announcements/getAll");
};

export const cashCapitalIncrease = data => {
	if ([0, -1].includes(store.getState().user.status)) return;
	if (store.getState().user.status === 1) {
		return request.post("/invest/cashCapitalIncrease", data);
	} else {
		return simulateRequest.post("/simulate/invest/cashCapitalIncrease", data);
	}
};

export const checkTransfer = () => {
	return request.get("/check/transfer");
};


export const kycVerify = data => {
	return request.post("/kyc/verify", data);
};

export const kycResult = () => {
	return request.post("/kyc/result");
};

export const getKycConfig = data => {
	return request.post("/kyc/config", data);
};

export const getTrialInfo = () => {
	return request.get("/trial_info");
};

export const sendTrial = data => {
	return request.post("/send_trial", data);
};

export const transferTrial = data => {
	return request.post("/transfer_trial", data);
};

export const transferTrialProfit = () => {
	return request.get("/transfer/trial_profit");
};

export const withdrawWelfare = data => {
	return request.post("/trial_withdraw/cash", data);
};

export const getAccountStatus = () => {
	return request.get("/account_status");
};

export const accountSwtich = () => {
	return request.get("/account/switch");
};

export const getDynamicInfo = () => {
	if ([0, -1].includes(store.getState().user.status)) return;
	if (store.getState().user.status === 1) {
		return request.get("/dynamic_info");
	} else {
		return simulateRequest.get("/simulate/dynamic_info");
	}
};

export const getUserChainAddress = () => {
	return request.post("/user/chainAddress");
};

export const checkUser = () => {
	return request.get("/check_user");
};

export const emailLogin = data => {
	return request.post("/email/login", data);
};

export const changeEmailAccount = data => {
	return request.post("/email/account", data);
};

export const getAccountList = () => {
	return request.get("/account/list");
};

export const accountChange = data => {
	return request.post("/account/change", data);
};

export const getMyInfo = () => {
	if ([0, -1].includes(store.getState().user.status)) return;
	if (store.getState().user.status === 1) {
		return request.get("/my");
	} else {
		return simulateRequest.get("/simulate/my");
	}
};

export const trialCode = data => {
	return request.post("/trial_code", data);
};

export const investUpgrade = data => {
	if ([0, -1].includes(store.getState().user.status)) return;
	if (store.getState().user.status === 1) {
		return request.post("/invest/upgrade", data);
	} else {
		return simulateRequest.post("/simulate/invest/upgrade", data);
	}
};

export const investRetrieve = data => {
	if ([0, -1].includes(store.getState().user.status)) return;
	if (store.getState().user.status === 1) {
		return request.post("/invest/retrieve", data);
	} else {
		return simulateRequest.post("/simulate/invest/retrieve", data);
	}
};

export const getMemberInfo = data => {
	return request.post("/member_info", data);
};

export const getStudioInfo = () => {
	return request.get("/studio_info");
};

export const getStudioIncome = () => {
	return request.get("/studio_income");
};

export const getDirectMemberInfo = data => {
	return request.post("/direct_member", data);
};

export const checkAuthUser = () => {
	return request.get("/auth/user");
};

export const authSendCode = () => {
	return request.get("/auth/sendCode");
};

export const authData = data => {
	return request.post("/auth/data", data);
};

export const getIp = () => {
	return request.get("/ip");
};

export const getUserTrial = () => {
	return request.get("/user/trial");
};

export const getExchangeInfo = () => {
	return request.get("/exchange/info");
};

export const exchangeTransferTrial = () => {
	return request.get("/exchange/transfer/trial");
};

export const getArbiInstantTrialList = () => {
	return request.get("/arbi_trial_instant_list");
};

export const createTrialInstant = data => {
	return request.post("/create/trial_instant", data);
};

export const getStudioWeek = () => {
	return request.get("/studio/week");
};

export const studioWeekReceive = data => {
	return request.post("/studio/week/receive", data);
};

export const getStudioInfo2 = () => {
	return request.get("/studio/info");
};
