import React, { useEffect } from "react";
import loading from "@/assets/lottie/loading.json";
import Lottie from "lottie-react";
import { useSelector } from "react-redux";

function Loading() {
	const { count } = useSelector((state) => state.loading);

	useEffect(() => {
		document.body.style.overflow = count > 0 ? "hidden" : "auto";
	}, [count]);

	return (
		<>
			{count > 0 && (
				<div className="bg-secondary-300 opacity-[80%] fixed z-[1000] bottom-0 left-0 right-0 top-0 flex items-center justify-center">
					<Lottie animationData={loading} loop={true} className="w-[150px]"/>
				</div>
			)}
		</>
	);
}

export default Loading;