import React from "react";

function CustomButton({ children, className, disabled, onClick, ...props }) {
	return (
		<button
			className={`w-full py-[9px] rounded-[4px] 
				${disabled ? "bg-primary-800 text-primary-100" : "bg-secondary-100 text-primary-100 cursor-pointer"}
				${className}
			`}
			onClick={disabled ? () => {} : onClick}
			{...props}
		>
			{children}
		</button>
	);
}

export default CustomButton;