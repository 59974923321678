import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	account: "custom",
	uuid: "email",
	show: false,
	group: 2 // cn: 1, en: 2, vn: 3
};

export const livechatSlice = createSlice({
	name: "livechat",
	initialState,
	reducers: {
		setCustomerData: (state, { payload }) => {
			state.account = payload.account;
			state.uuid = payload.uuid;
		},
		setShow: (state, { payload }) => {
			state.show = payload;
		},
		setGroup: (state, { payload }) => {
			state.group = payload;
		}
	},
});

export const { setCustomerData, setShow, setGroup } = livechatSlice.actions;

export default livechatSlice.reducer;
