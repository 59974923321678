import React, { useEffect, Suspense, useState } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import routers from "./router";
import Loading from "@/components/Loading";
import GlobalMessage from "@/components/Message";
import { useSelector } from "react-redux";
import { getAnnouncement } from "@/api";
import NewsModal from "@/components/NewsModal";
import { useTranslation } from "react-i18next";
import Crontab from "@/Crontab";
import GernalModal from "./GernalModal";

const router = createBrowserRouter(routers);
function App() {
	const { i18n } = useTranslation();
	const user = useSelector(state => state.user);
	const [news, setNews] = useState({});
	const [forceUpdate, setForceUpdate] = useState(false);

	useEffect(() => {
		if (location.pathname === "/introduceFriend") return;

		if (localStorage.getItem("token") && !user.init) {
			getAnnouncementHandler();
		} else if (!localStorage.getItem("token")){
			getAnnouncementHandler();
		}
	}, [user.account, user.init, i18n.language]);

	useEffect(() => {
		setTimeout(() => {
			setForceUpdate(true);
		}, 1500);
	}, []);

	const getAnnouncementHandler = async () => {
		const { code, data } = await getAnnouncement();
		if (code === 0) {
			setNews(data.announcementList?.[0]);
		}
	};


	return (
		<div className="min-h-[100vh] h-max pcAdapt bg-primary-100">
			<Crontab />
			<GlobalMessage />
			<Loading />
			<Suspense fallback={<div />}>
				<RouterProvider router={router} />
			</Suspense>
			{news?.id && 
				<NewsModal news={news} onClose={() => setNews({})}/>
			}
			<GernalModal />
			{forceUpdate}
		</div>
	);
}

export default App;
