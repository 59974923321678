import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getDictionary } from "@/api";
import Big from "big.js";

const initialState = {
	value: {},
	incomeRate: [],
	invest_type: [],
	sevenRate: 0,
	mpy: 0,
};

export const getDictionaryAsync = createAsyncThunk("user/getDictionary", async () => {
	const { data } = await getDictionary();
	return data;
});

export const userSlice = createSlice({
	name: "user",
	initialState,
	reducers: {
		setUserProfile: (state, { payload }) => {
			payload.config = payload.config || [];
			payload.income_rate = payload.income_rate || [];

			let temp = {};
			payload.config.forEach(e => temp[e.name] = e.value);

			temp.invest_trial_harvest = Big(temp.invest_trial_amount).times(temp.invest_trial_days).times(0.6).div(100).round(2, 0).toString();
			state.value = temp;
			
			payload.income_rate.reverse();

			let sevenRate = Big(0);
			payload.income_rate.forEach(e => sevenRate = sevenRate.plus(e.rate));
			state.incomeRate = payload.income_rate;

			let sevenRateAvg = sevenRate.div(7);

			state.sevenRate = sevenRate.round(2, 0).toString();

			const mpy = Big(1 + (sevenRateAvg / 100)).pow(30).minus(1).times(100).round(2, 0).toString();
			state.mpy = mpy;

			payload.invest_type.sort((a, b) => a.days - b.days);
			state.invest_type = payload.invest_type;
		},
	},
	extraReducers(builder) {
		builder
			.addCase(getDictionaryAsync.fulfilled, (state, action) => {
				if (action.payload) {
					userSlice.caseReducers.setUserProfile(state, action);
				}
			});
	},
});

export const { setUserProfile } = userSlice.actions;

export default userSlice.reducer;
