import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUserProfile } from "@/store/userSlice";
import { getUserBalanceAsync } from "@/store/balanceSlice";
import { getDictionaryAsync } from "@/store/configSlice";
import { addTimestamp, getSystemTimeSync } from "@/store/timestampSlice";
import packageJson from "../package.json";
import { setSuspend } from "@/api/request";
import { setSuspend as setSuspend2 } from "@/api/simulateRequest";
import { addLoading, reduceLoading } from "@/store/loadingSlice";
import { useTranslation } from "react-i18next";
import { setCustomerData } from "@/store/livechatSlice";
import { showMessage } from "@/components/Message";
// import * as Sentry from "@sentry/react";

function Crontab() {
	const { t, i18n } = useTranslation();
	const dispatch = useDispatch();
	const user = useSelector(state => state.user);
	const config = useSelector(state => state.config);
	const livechat = useSelector(state => state.livechat);

	const intervalRef1 = useRef(null);
	const intervalRef2 = useRef(null);
	const intervalRef3 = useRef(null);
	const intervalRef4 = useRef(null);
	const intervalRef5 = useRef(null);
	const intervalRef6 = useRef(null);
	const proccessRef = useRef({});

	useEffect(() => {
		loadLanguages();
	}, []);

	useEffect(() => {
		window.addEventListener("online", handleOnline);
		window.addEventListener("offline", handleOffline);
	
		return () => {
			window.removeEventListener("online", handleOnline);
			window.removeEventListener("offline", handleOffline);
		};
	}, []);

	const handleOnline = () => {
		showMessage(t("reconnect"));
	};
	
	const handleOffline = () => {
		showMessage(t("disconnect"));
	};
	
	useEffect(() => {
		async function get() {
			dispatch(addLoading());
			await dispatch(getSystemTimeSync());
			dispatch(getDictionaryAsync());
			if (localStorage.getItem("token")) {
				initUserData();
			}
			dispatch(reduceLoading());
		}
		get();
	}, []);

	useEffect(() => {
		document.addEventListener("visibilitychange", checkVisibilityState);

		intervalRef4.current = setInterval(() => {
			dispatch(addTimestamp());
		}, 1000);

		intervalRef5.current = setInterval(() => {
			if (proccessRef.intervalRef5) return;
			proccessRef.intervalRef5 = true;
			dispatch(getSystemTimeSync())
				.finally(() => proccessRef.intervalRef5 = false);
		}, 20 * 1000);

		return () => {
			clearInterval(intervalRef4.current);
			clearInterval(intervalRef5.current);
			document.removeEventListener("visibilitychange", checkVisibilityState);
		};
	}, []);

	useEffect(() => {
		intervalRef3.current = setInterval(() => {
			if (proccessRef.intervalRef3) return;
			proccessRef.intervalRef3 = true;
			dispatch(getDictionaryAsync())
				.finally(() => proccessRef.intervalRef3 = false);
		}, 1 * 60 * 1000);
		return () => {
			clearInterval(intervalRef3.current);
		};
	}, []);

	// useEffect(() => {
	// 	initUserData();
	// }, [user.status]);

	useEffect(() => {
		// intervalRef6.current = setInterval(() => {
		// 	if (localStorage.getItem("token") && user.status === 0) {
		// 		dispatch(getAccountStatusThunk());
		// 	}
		// }, 2 * 1000);

		intervalRef1.current = setInterval(() => {
			if (localStorage.getItem("token")) {
				if (proccessRef.intervalRef1) return;
				proccessRef.intervalRef1 = true;
				dispatch(getUserBalanceAsync())
					.finally(() => proccessRef.intervalRef1 = false);
			}
		}, 10 * 1000);

		intervalRef2.current = setInterval(() => {
			if (localStorage.getItem("token")) {
				if (proccessRef.intervalRef2) return;
				proccessRef.intervalRef2 = true;
				dispatch(getUserProfile())
					.finally(() => proccessRef.intervalRef2 = false);
			}
		}, 1 * 60 * 1000);
		
		return () => {
			clearInterval(intervalRef1.current);
			clearInterval(intervalRef2.current);
			clearInterval(intervalRef6.current);
		};
	}, [user]);

	useEffect(() => {
		if (import.meta.env.MODE === "development") return;

		if (config.value.front_version && (config.value.front_version !== packageJson.version)) {
			window.location.reload();
		}
	}, [config]);

	useEffect(() => {
		if (user.account && user.account !== livechat.account) {
			dispatch(setCustomerData({
				account: user.account,
				uuid: user.uuid
			}));
			// Sentry.setUser({ username: user.account });
		}
	}, [user.account]);

	const checkVisibilityState = async() => {
		if (document.visibilityState === "visible") {
			await dispatch(getSystemTimeSync());
			setSuspend(false);
			setSuspend2(false);
		} else {
			setSuspend(true);
			setSuspend2(true);
		}
	};

	const loadLanguages = async () => {
		try {
			const lang = localStorage.getItem("lang") || "en";
			const translation = await import(`./locales/${lang}.json`);
			i18n.addResourceBundle(lang, "translation", translation, true, true);
		} catch (error) {
			localStorage.setItem("lang", "en");
			window.location.reload();
		}
	};

	const initUserData = async() => {
		if (user.status === 0) return;
		dispatch(addLoading());
		await dispatch(getUserProfile());
		await dispatch(getUserBalanceAsync());
		dispatch(reduceLoading());
	};
}

export default Crontab;