import React, { lazy } from "react";
import ErrorPage from "@/views/ErrorPage";
const MainLayout = lazy(() => import("@/layouts/mainLayout"));
const Home = lazy(() => import("@/views/Home"));
const AuthMainLayout = lazy(() => import("@/layouts/authLayout"));
const Auth = lazy(() => import("@/views/Auth"));
const Register = lazy(() => import("@/views/Auth/Register"));
const ForgetPassword = lazy(() => import("@/views/ForgetPassword"));
const Arbi = lazy(() => import("@/views/Arbi"));
const FollowOrder = lazy(() => import("@/views/FollowOrder"));
const OrderDetail = lazy(() => import("@/views/OrderDetail"));
const OnceGameDetail = lazy(() => import("@/views/OnceGameDetail"));
const PrincipalDnW = lazy(() => import("@/views/PrincipalDnW"));
const KycResponse = lazy(() => import("@/views/KycResponse"));
// const Kyc = lazy(() => import("@/views/Kyc"));
const MoneyLog = lazy(() => import("@/views/MoneyLog"));
const InviteFriend = lazy(() => import("@/views/InviteFriend"));
const BindEmailOrPhone = lazy(() => import("@/views/BindEmailOrPhone"));
const ChangeLoginPassword = lazy(() => import("@/views/ChangeLoginPassword"));
const ChangePayPassword = lazy(() => import("@/views/ChangePayPassword"));
const SystemCenter = lazy(() => import("@/views/SystemCenter"));
const RebindEmailOrPhone = lazy(() => import("@/views/RebindEmailOrPhone"));
const My = lazy(() => import("@/views/My"));
const SystemNotify = lazy(() => import("@/views/SystemNotify"));
const ChangeAccount = lazy(() => import("@/views/ChangeAccount"));
const IntroduceFriend = lazy(() => import("@/views/IntroduceFriend"));
const AboutUs = lazy(() => import("@/views/AboutUs"));
const Community = lazy(() => import("@/views/Community"));
const TrialQrcode = lazy(() => import("@/views/TrialQrcode"));
const UsdtTrade = lazy(() => import("@/views/UsdtTrade"));
const UsdtTradeList = lazy(() => import("@/views/UsdtTradeList"));
const UsdtTradeDetailBuy = lazy(() => import("@/views/UsdtTradeDetail/Buy"));
const UsdtTradeDetailSell = lazy(() => import("@/views/UsdtTradeDetail/Sell"));
// const LockAccount = lazy(() => import("@/views/LockAccount"));
const OrderList = lazy(() => import("@/views/OrderList"));
const CommunityIntroduce = lazy(() => import("@/views/CommunityIntroduce"));
const Reward = lazy(() => import("@/views/Reward"));

const router = [
	{
		path: "/auth",
		element: <AuthMainLayout />,
		errorElement: <ErrorPage />,
		children: [
			{
				index: true,
				element: <Auth />
			},
			{
				path: "/auth/register",
				element: <Register />
			},
			{
				path: "/auth/forgetPassword",
				element: <ForgetPassword />
			},
		]
	},
	{
		path: "/",
		element: <MainLayout />,
		errorElement: <ErrorPage />,
		children: [
			{
				index: true,
				element: <Home />,
			},
			{
				path: "/home",
				element: <Home />,
			},
			{
				path: "/orderList",
				element: <OrderList />,
			},
			// {
			// 	path: "/lockAccount",
			// 	element: <LockAccount />,
			// },
			{
				path: "/communityIntroduce",
				element: <CommunityIntroduce />,
			},
			{
				path: "/trialQrcode/:id",
				element: <TrialQrcode />
			},
			{
				path: "/arbi",
				element: <Arbi />
			},
			{
				path: "/followOrder",
				element: <FollowOrder />
			},
			{
				path: "/orderDetail",
				element: <OrderDetail />
			},
			{
				path: "/aboutUs/:tab?",
				element: <AboutUs />
			},
			{
				path: "/onceGameDetail/:no",
				element: <OnceGameDetail />
			},
			{
				path: "/principalDnW/:action?",
				element: <PrincipalDnW />,
			},
			{
				path: "/kycResponse",
				element: <KycResponse />,
			},
			// {
			// 	path: "/kyc",
			// 	element: <Kyc />,
			// },
			{
				path: "/moneyLog/:action?",
				element: <MoneyLog />,
			},
			{
				path: "/inviteFriend",
				element: <InviteFriend />,
			},
			{
				path: "/changeLoginPassword",
				element: <ChangeLoginPassword />
			},
			{
				path: "/community",
				element: <Community />
			},
			{
				path: "/changePayPassword",
				element: <ChangePayPassword />
			},
			{
				path: "/bindEmailOrPhone",
				element: <BindEmailOrPhone />
			},
			{
				path: "/rebindEmailOrPhone",
				element: <RebindEmailOrPhone />
			}, 
			{
				path: "/introduceFriend",
				element: <IntroduceFriend />
			},
			{
				path: "/systemCenter",
				element: <SystemCenter />
			},
			{
				path: "/changeAccount",
				element: <ChangeAccount />
			},
			{
				path: "/reward",
				element: <Reward />
			},
			{
				path: "/my",
				element: <My />
			},
			{
				path: "/usdtTrade/list/:action?",
				element: <UsdtTradeList />,
			},
			{
				path: "/usdtTrade/detail/buy/:id",
				element: <UsdtTradeDetailBuy />,
			},
			{
				path: "/usdtTrade/detail/sell/:id",
				element: <UsdtTradeDetailSell />,
			},
			{
				path: "/usdtTrade/:action?",
				element: <UsdtTrade />,
			},
			{
				path: "/systemNotify",
				element: <SystemNotify />
			},
			{
				path: "*",
				element: <Home />	
			}
		]
	}
];
export default router;
