import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "./i18n";
import App from "./App";
import { Provider } from "react-redux";
import store from "./store";
import { injectStore } from "@/api/request";
import { injectStore as injectStore2 } from "@/api/simulateRequest";
import { injectStore as injectStore3 } from "@/api";
// import * as Sentry from "@sentry/react";

// Sentry.init({
// 	dsn: "https://47c104f3c7283a5d65d6f8c96665d4b9@o4505392028516352.ingest.sentry.io/4506536191393792",
// 	integrations: [
// 		new Sentry.BrowserTracing({
// 			// Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
// 			tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
// 		}),
// 		new Sentry.Replay(),
// 	],
// 	// Performance Monitoring
// 	tracesSampleRate: 0.7, // Capture 100% of the transactions, reduce in production!
// 	// Session Replay
// 	replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
// 	replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
// 	beforeSend(event, hint) {
// 		const error = hint.originalException;
// 		const excludeList = ["Wait systemTime response", "zaloJSV2"];
// 		const isExclude = excludeList.some(item => error?.message?.includes(item));
// 		if (isExclude) {
// 			return null;
// 		}
// 		return event;
// 	}
// });

injectStore(store);
injectStore2(store);
injectStore3(store);
ReactDOM.createRoot(document.getElementById("root")).render(
	<Provider store={store}>
		<App />
	</Provider>
);
