import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	show: ""
};

export const modalSlice = createSlice({
	name: "modal",
	initialState,
	reducers: {
		setShow: (state, { payload }) => {
			state.show = payload;
		},
		resetShow: (state) => {
			state.show = "";
		},
	},
});

export const { setShow, resetShow } = modalSlice.actions;

export default modalSlice.reducer;
