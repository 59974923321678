import JSEncrypt from "jsencrypt";
import CryptoJS from "crypto-js";
import dayjs from "dayjs";
import Big from "big.js";
import { logoutApi } from "@/api";

export const rsaEncrypt = (data, pubKey = import.meta.env.VITE_RSA_PUBLIC_KEY) => {
	const encryptor = new JSEncrypt({
		default_key_size: 4096
	});
	encryptor.setPublicKey(pubKey);
	const rsaPassWord = encryptor.encrypt(data);
	return rsaPassWord;
};

export const aesDecrypt = msg => {
	const key = CryptoJS.enc.Utf8.parse(import.meta.env.VITE_AES_KEY);
	const decrypted = CryptoJS.AES.decrypt(
		{
			ciphertext: CryptoJS.enc.Hex.parse(msg)
		},
		key,
		{
			iv: key,
			padding: CryptoJS.pad.Pkcs7,
			mode: CryptoJS.mode.CBC
		}
	).toString(CryptoJS.enc.Utf8);
	return decrypted;
};

export const converTimestamp = (timestamp, format="YYYY-MM-DD HH:mm") => {
	try {
		return dayjs.unix(timestamp).format(format);
	} catch (error) {
		console.error(error);
		return "";
	}
};

export const calculateArbi = (money, type, odds) => {
	const f = parseFloat(money);
	if (Number.isNaN(f) || f <= 0) {
		return {
			home: [0, 0],
			away: [0, 0],
			draw: [0, 0],
			total: 0,
			arbi: 0,
			arbiPercent: 0
		};
	}
	const odds_home = parseFloat(odds.home_odds) || parseFloat(odds.over_odds);
	const odds_draw = parseFloat(odds.draw_odds);
	const odds_away = parseFloat(odds.away_odds) || parseFloat(odds.under_odds);
	if (type === 3) {
		const diff = (1 / ((1 / odds_home) + (1 / odds_draw) + (1 / odds_away))) - 1;
		const profit = (f + (f * diff));
		const homeStake = Big(profit).div(odds_home).round(3, 0).toString();
		const awayStake = Big(profit).div(odds_away).round(3, 0).toString();
		const drawStake = Big(profit).div(odds_draw).round(3, 0).toString();
		const homeReturn = Big(homeStake).times(odds_home).round(3, 0).toString();
		const awayReturn = Big(awayStake).times(odds_away).round(3, 0).toString();
		const drawReturn = Big(drawStake).times(odds_draw).round(3, 0).toString();

		return {
			home: [homeStake, homeReturn],
			away: [awayStake, awayReturn],
			draw: [drawStake, drawReturn],
			total: Big(profit).round(2, 0).toString(),
			arbi: Big(profit).minus(f).round(2, 0).toString(),
			arbiPercent: Big(profit).minus(f).div(f).times(100).round(2, 0).toString()
		};
	} else {
		const diff = (1 / ((1 / odds_home) + (1 / odds_away))) - 1;
		const profit = (f + (f * diff));
		const homeStake = Big(profit).div(odds_home).round(3, 0).toString();
		const awayStake = Big(profit).div(odds_away).round(3, 0).toString();
		const homeReturn = Big(homeStake).times(odds_home).round(3, 0).toString();
		const awayReturn = Big(awayStake).times(odds_away).round(3, 0).toString();
		return {
			home: [homeStake, homeReturn],
			away: [awayStake, awayReturn],
			draw: [0, 0],
			total: Big(profit).round(2, 0).toString(),
			arbi: Big(profit).minus(f).round(2, 0).toString(),
			arbiPercent: Big(profit).minus(f).div(f).times(100).round(2, 0).toString()
		};
	}
};

export const logout = (goLogin) => {
	logoutApi().then(() => {
		localStorage.removeItem("token");
		localStorage.removeItem("bannerType");
		localStorage.removeItem("announcement");
		localStorage.removeItem("newsModal");
		localStorage.removeItem("sendTime");
		if (goLogin) {
			window.location.href = "/auth";
		} else {
			window.location.reload();
		}
	});
};

export const randomAccount = () => {
	const alphaSeed = ["a", "b", "c", "d", "e", "f", "g", "h", "j", "k", "m", "n", "p", "q", "r", "s", "t", "u", "v", "w", "x", "y", "z"];
	const numberSeed = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];

	const account = [1,2,3,4,5,6,7,8,9,10].reduce((acc, cur) => {
		if (cur <= 4) {
			const rand = Math.floor(Math.random() * alphaSeed.length);
			acc += alphaSeed[rand];
		} else {
			const rand = Math.floor(Math.random() * numberSeed.length);
			acc += numberSeed[rand];
		}
		return acc;
	}, "");
	return account;
};

export const escapeEmail = (email) => {
	if (!email) return "";
	const [name, domain] = email.split("@");
	const nameLength = name.length;
	const nameStart = name.slice(0, 2);
	const nameEnd = name.slice(nameLength - 2, nameLength);
	return `${nameStart}***${nameEnd}@${domain}`;
};
