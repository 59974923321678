import axios from "axios";
import i18n from "@/i18n";
import { showMessage } from "@/components/Message";
import { logout } from "@/utils";
import md5 from "crypto-js/md5";
import { v4 as uuidv4 } from "uuid";

let store;

let suspended = false;

export const injectStore = _store => {
	store = _store;
};

export const setSuspend = (payload) => {
	suspended = payload;
};

const service = axios.create({
	baseURL: `${import.meta.env.VITE_API_URL}/api`,
	headers: {
		"Content-Type": "application/json"
	},
	timeout: 20 * 1000
});

service.interceptors.request.use(
	config => {
		if (suspended && config.url !== "/system_time") {
			return Promise.reject("Wait systemTime response");
		}
		if (!["/system_time", "/auth/sendCode", "/auth/user", "/auth/data", "/loginout"].includes(config.url) && store.getState().user.status === -1) {
			return Promise.reject("Reject");
		}

		const token = localStorage.getItem("token");
		if (token) {
			config.headers.token = token;
		}
		const timestamp = store.getState().timestamp.value || parseInt(new Date().getTime() / 1000);
		const uuid = uuidv4();
		config.headers["X-Ca-Timestamp"] = timestamp.toString();
		config.headers["X-Ca-Nonce"] = uuid;
		config.headers["X-Ca-Signature"] = md5(`${timestamp}${uuid}${import.meta.env.VITE_API_PRIVATE_KEY}`).toString();
		config.headers.lang = i18n.language || "en";
		return config;
	},
	error => {
		throw error.response;
	}
);

service.interceptors.response.use(
	response => {
		const code = response?.data?.code || 0;
		const exclude = [-815];
		if (response.data.code !== 0 && !exclude.includes(code)) {
			const trans = i18n.t(`errorCode.${response.data.code}`, {
				value1: response?.data?.data?.value1 || "",
			});
			showMessage(trans, 3000);
		}
		return response.data;
	},
	error => {
		const status = error?.response?.status;
		if (error.code === "ECONNABORTED") {
			showMessage(i18n.t("timeout"));
		} else {
			if (status === 401) {
				logout(true);
				showMessage(i18n.t("tokenExpired"), 3000);
			}
			if (status === 413) {
				showMessage(i18n.t("sizeTooBig"));
			}
		}
		return Promise.reject(error);
	}
);

export default service;
